import { Router, Route, Switch, Redirect } from "react-router-dom";

import { useRecoilValue } from "recoil";

import { authAtom } from "state";
import {
  Nav,
  Alert,
  PrivateRoute,
  HomeComponent,
  Account,
  IncompleteServicesComponent,
  CompleteServicesComponent,
  PlatesComponent,
  Footer,
  Map,
  IdentifyPlateComponent,
  TowTruckLocationComponent
} from "_components";

import { history } from "_helpers/History";
//import Scss
import "./assets/scss/themes.scss";
import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/dist/styles/ag-theme-material.css"; // Optional theme CSS
import { ServiceDetail } from "_components/pages/service-detail/ServiceDetail";
import { ServicesComponent } from "_components/pages/services/ServicesComponent";
import ApiService from "services/ApiService";
import {SupervisorAdminRoute} from "./_components/shared/SupervisorAdminRoute";
import CustomerAndTowTruckLocationComponent
  from "./_components/pages/customer-and-tow-truck-location/CustomerAndTowTruckLocationComponent";

export { App };

function App() {
  const auth = useRecoilValue(authAtom);
  ApiService.init()
  return (
    <div className={"App ag-theme-material" + (auth ? " bg-light" : "")}>
      <Router history={history}>
        <Nav />
        <Alert />
        <Switch>
          <PrivateRoute exact path="/" component={HomeComponent} />
          <Route path="/services" component={ServicesComponent} />
          <PrivateRoute
            path="/complete-service"
            component={CompleteServicesComponent}
          />

          <PrivateRoute
            path="/incomplete-service"
            component={IncompleteServicesComponent}
          />
          <PrivateRoute path="/plates" component={PlatesComponent} />
          <Route path="/account" component={Account} />
          <Route path="/service-detail/:id" component={ServiceDetail} />
          <Route path="/map" component={Map} />
          <SupervisorAdminRoute path="/identifyPlate" component={IdentifyPlateComponent} />
          <Route path="/ctl/:guid" component={CustomerAndTowTruckLocationComponent} />
          <Route path="/cl/:guid" component={TowTruckLocationComponent} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}
